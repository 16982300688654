/* .App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {

background: linear-gradient(180deg, #DCDDDE 0%, #E8E8E8 31.84%, #FEFEFE 63.97%, #F2F3F4 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
} */

.App {
  text-align: center;
}







.title,
.subtitle {
  margin: 0;
}

.base {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100%;

  font-size: calc(10px + 2vmin);
  background: linear-gradient(180deg, #DCDDDE 0%, #E8E8E8 31.84%, #FEFEFE 63.97%, #F2F3F4 100%);
  color: #000;

  overflow-x: hidden;
  
}

.logoWrapper {
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 0;
  /* padding: 20px; */
}
.App-logo {
  height: 10vmin;
  min-height: 60px;
  padding-bottom: 4px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  position: relative;
  padding: 20px;
}
.content-container {
  z-index: 10;
  /* justify-content: flex-start;
  align-items: flex-start;
  text-align: left; */
  padding-top: 20px;

}

.temple {
  height: 80vh;
  bottom: 0vh;
  /* right: 20px; */
  z-index: 0;
    position: absolute;

}
@media (max-width: 768px) {
  .base {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .content-container {
    padding-top: 8vh;
    text-align: center;

  }

  .temple {
    position: relative;
    right: 0;
    height: 80vh;
    /* bottom: 10vh; */
  }

}

.title{
  padding: 8px;
}
